<template>
  <div>
  <!-- <div v-if="fileuploadeCount === 'One File'">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center mb-2">
        <div class="font-weight-bold mt-3">File Count: {{ fileuploadeCount }}</div>
        <v-icon color="success" class="ml-2 mt-2" v-if="(filevalues && filevalues[firstKey] && filevalues[firstKey][0] || {}).processed === 1">mdi-check-circle</v-icon>
        <v-icon color="info" class="ml-2  mt-2" v-else>mdi-timer-sand</v-icon>
        <v-spacer></v-spacer>
        <div class="d-flex">
		<v-btn class="shadow-off" medium @click="fileuploadType()">
			<v-icon x-large class="cloud-upload-outline">
				mdi-cloud-upload-outline
			</v-icon>
			</v-btn>
        </div>
      </div>
      <div class="grey--text text--darken-1">Required columns:</div>
      <div v-if="requiredColum && requiredColum.columnmap">
        <v-chip x-small class="mr-1 mb-1" v-for="(item, i) in requiredColum.columnmap" :key="i">
          {{ item[1] || 'Unknown' }}
        </v-chip>
      </div>

	<span v-if="!Object.keys(filevalues).length === 0" @click="$nova.downloadFile((filevalues && filevalues[firstKey] && filevalues[firstKey][0]._id))" class="cursor-pointer blue--text text--darken-2 caption">
			<v-chip class="ma-2 cursor-pointer"	close color="blue--text" label text-color="white" @click:close="uploadFileDelete((filevalues && filevalues[firstKey] && filevalues[firstKey][0]._id))">
			<v-icon left>mdi-file</v-icon>
			{{ filevalues && filevalues[firstKey] && filevalues[firstKey][0].uploadname || ""}}
			</v-chip>
		</span>
    </div>
  </div> -->
  <div v-if="(fileuploadeCount === 'More' || fileuploadeCount === 'One File')">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center mb-2">
        <div class="font-weight-bold mt-3">File Count: {{ fileuploadeCount }}</div>
        <v-icon color="success" class="ml-2" v-if="allItemsProcessed">mdi-check-circle</v-icon>
        <v-icon color="info" class="ml-2 mt-2" v-else>mdi-timer-sand</v-icon>
        <v-spacer></v-spacer>
        <div class="d-flex">
			
		<v-btn class="shadow-off" medium @click="fileuploadType()">
        <v-icon x-large class="cloud-upload-outline">
          mdi-cloud-upload-outline
        </v-icon>
      </v-btn>
        </div>
      </div>
      <div class="grey--text text--darken-1">Required columns:</div>
      <div v-if="requiredColum && requiredColum.columnmap">
        <v-chip x-small class="mr-1 mb-1" v-for="(item, i) in requiredColum.columnmap" :key="i" :id="'automation-test-'+ i">
          {{ item[1] || 'Unknown' }}
        </v-chip>
      </div>
	<div class="mt-3 mb-3" v-if="filevalues && filevalues[firstKey] && filevalues[firstKey].length > 0">
	<span v-for="(item, i) in filevalues && filevalues[firstKey]" :key="i" @click="$nova.downloadFile((item._id))" class="cursor-pointer blue--text text--darken-2 caption" :id="'automation-test-'+ i">
		<span v-if="item.processed === 1">
			<v-chip class="ma-2 cursor-pointer"	close color="blue--text" label text-color="white" @click:close="uploadFileDelete(item._id)" :id="'automation-test-'+ i">
			<v-icon left>mdi-file</v-icon>
			{{item.uploadname}}
			</v-chip>
		</span>
		<span v-else class="red--text">
		<v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
			<v-chip class="ma-2 cursor-pointer"	close color="error--text" label text-color="white" v-bind="attrs" v-on="on" @click:close="uploadFileDelete(item._id)">
			{{item.uploadname}} 
		</v-chip>
		</template>
		<span>{{item.processmessage}}</span>
		</v-tooltip>
		</span>
	</span>
	</div>
	<div >

	</div>
    </div>
  </div>
  <div v-if="fileuploadeCount === 'Two File'">
    <div v-for="(item, i) in files" :key="i" class="hoverable" :id="'automation-test-'+ i">
      <div class="px-2 py-1">
        <div class="d-flex align-center">
          <div class="font-weight-bold" :id="'automation-test-'+ i">
            <span class="mr-2" :id="'automation-test-'+ i">{{ i + 1 }}.</span>
            <span>{{ item.name }}</span>
            <v-icon color="success" class="ml-2" :id="'automation-test-'+ i"   v-if="isAllFileProcessed(item._id)">mdi-check-circle</v-icon>
            <v-icon color="info" class="ml-2" :id="'automation-test-'+ i" v-else>mdi-timer-sand</v-icon>
          </div>
          <v-spacer></v-spacer>
          <div v-if="fileproerr[item._id]" class="ml-2">
            <span class="error--text">{{ fileproerr[item._id] || "Unknown Error" }}</span>
          </div>
          <div v-if="fileprostage[item._id] === 1 && filecount" class="ml-2">
            <v-chip small color="info" :value="true" label> Validating </v-chip>
          </div>
          <div v-else-if="(filevalues[item._id] || {}).processed === 0" class="ml-2">
            <v-chip small label color="warning" :value="true"> Processing </v-chip>
          </div>
          <div v-else-if="fileprostage[item._id] === 2 && filecount" class="ml-2">
            <v-chip small label color="info" :value="true"> Uploading </v-chip>
          </div>
		<v-btn class="shadow-off" :id="'automation-test-'+ i" medium @click="fileuploadTypeTwoFile(item._id, item.columns,filevalues[item._id])">
            <v-icon x-large class="cloud-upload-outline">
          mdi-cloud-upload-outline
        </v-icon>
      </v-btn>
        </div>
        <div>
          <div class="grey--text text--darken-1">Required columns:</div>
          <div v-if="(fileconfig[item._id] || {}).columnmap">
            <v-chip x-small class="mr-1 mb-1"  v-for="(item, i) in (fileconfig[item._id] || {}).columnmap || []" :key="i" :id="'automation-test-'+ i" >{{ item[1] || "Unknown" }}</v-chip>
          </div>
          <div v-else>
            <v-chip x-small class="mr-1 mb-1" v-for="(item, i) in files[i].columns" :key="i" :id="'automation-test-'+ i" >{{ item.name }}</v-chip>
          </div>
        </div>
		<div class="mt-3 mb-3" v-if="filevalues && filevalues[item._id] && filevalues[item._id].length > 0">
	<span v-for="(items, i) in filevalues && filevalues[item._id]" :key="i" @click="$nova.downloadFile((items._id))" class="cursor-pointer blue--text text--darken-2 caption" :id="'automation-test-'+ i" >
		<span v-if="items.processed === 1">
			<v-chip :id="'automation-test-'+ i"  class="ma-2 cursor-pointer"  close color="blue--text" label text-color="white" @click:close="uploadFileDelete(items._id)">
			<v-icon left>mdi-file</v-icon>
			{{items.uploadname}}
			</v-chip>
		</span>
		<span v-else class="red--text">
		<v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
			<v-chip class="ma-2 cursor-pointer"	close color="error--text" label text-color="white" v-bind="attrs" v-on="on" @click:close="uploadFileDelete(items._id)">
			{{items.uploadname}} 
		</v-chip>
		</template>
		<span>{{item.processmessage}}</span>
		</v-tooltip>
		</span>
	</span>
	</div>
		<!-- <div class="mt-3 mb-3" v-if="filevalues && filevalues[item._id] && filevalues[item._id].length > 0">
		<span v-for="(item, i) in filevalues && filevalues[item._id]" :key="i" @click="$nova.downloadFile((item._id))" class="cursor-pointer blue--text text--darken-2 caption">
			{{item}}
			<span v-if="item.processed === 1">
			<v-chip class="ma-2 cursor-pointer"	close color="blue--text" label text-color="white" @click:close="uploadFileDelete(item._id)">
			<v-icon left>mdi-file</v-icon>
			{{item.uploadname}}
			</v-chip>
			</span>
			<span v-else class="red--text">
			{{item.processmessage}}
			</span>
		</span>
		</div> -->
      </div>
      <v-divider class=""></v-divider>
    </div>
  </div>
  <lb-dialog v-model="typeOffile" width="800" heading="File Upload Type" :loading="typeOffileloading">
    <template v-slot:body>
      <div>
        <div class="d-flex align-center justify-center">
          <v-row>
            <v-col cols="12" class="my-0 py-0" v-if="fileuploadeCount !== 'Two File'">
              <lb-dropdown class="flux-grow-1 mr-1" label="Upload Type*" :items="fileuploadtypeItemNofile" v-model="fileuptype" itemtext="description" itemvalue="_id" v-if="Object.keys(filevalues).length === 0" />
              <lb-dropdown class="flux-grow-1 mr-1" label="Upload Type*" :items="fileuploadtypeItem" v-model="fileuptype" itemtext="description" itemvalue="_id" v-else />
            </v-col>

			<v-col cols="12" class="my-0 py-0" v-else>
              <lb-dropdown class="flux-grow-1 mr-1" label="Upload Type*" :items="fileuploadtypeItemNofile" v-model="fileuptype" itemtext="description" itemvalue="_id" v-if="fileuploadeornot" />
              <lb-dropdown class="flux-grow-1 mr-1" label="Upload Type*" :items="fileuploadtypeItem" v-model="fileuptype" itemtext="description" itemvalue="_id" v-else />
            </v-col>

            <v-col cols="12" class="my-0 py-0" v-if="fileuploadeCount === 'Two File'">
              <lb-file label="" class="py-0 my-0" :drag="false" hidedetails :displayname="true" accept=".xlsx,.xls,.xlsm,.xlsb,.csv" v-model="fileuptypefile" />
            </v-col>
            <!-- <v-col cols="12" class="my-0 py-0" v-if="fileuploadeCount === 'One File'">
              <lb-file label="" class="py-0 my-0" :drag="false" hidedetails :displayname="false" accept=".xlsx,.xls,.xlsm,.xlsb,.csv" v-model="fileuptypefile" />
            </v-col> -->
            <v-col cols="12" class="my-0 py-0" v-if="(fileuploadeCount === 'More' || fileuploadeCount === 'One File')">
              <lb-file label="" class="py-0 my-0" :drag="false" hidedetails :displayname="false" accept=".xlsx,.xls,.xlsm,.xlsb,.csv" v-model="fileuptypefile" />
            </v-col>
            <v-col cols="12" class="my-0 py-0 mt-2">
              <lb-datatable :headers="headersFile" :items="fileuploadeList" :enableslot="['action']" v-if="fileuploadeList.length > 0">
                <template v-slot:action="{ item }">
                  <div class="d-flex">
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" color="error" @click.stop="deleteFile(item)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </lb-datatable>
            </v-col>
            <v-col cols="12" class="my-0 py-0" v-if="singleMultfileError">
              <v-alert dense text type="error" class="ml-2 mt-3"> {{ singleMultfileError }}</v-alert>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <span class="caption">*Required</span>
      <v-spacer></v-spacer>
      <v-btn small color="fbcPrimary" @click="uploadeFileTwo(fileuptype,fileuploadeList,fileuploadeCount)" v-if="fileuploadeCount === 'Two File'">
        <span>Upload</span>
      </v-btn>
      <v-btn small color="fbcPrimary" @click="uploadeFiles(fileuptype,fileuploadeList,fileuploadeCount)" v-if="(fileuploadeCount === 'More' || fileuploadeCount === 'One File')">
        <span>Upload</span>
      </v-btn>
      <!-- <v-btn small color="fbcPrimary" @click="uploadeFiles(fileuptype,fileuploadeList,fileuploadeCount)" v-if="fileuploadeCount === 'One File'">
        <span>Upload</span>
      </v-btn> -->
      <v-btn small color="fbcPrimary" @click="typeOffile=false">
        <span>Cancel</span>
      </v-btn>
    </template>
  </lb-dialog>
</div>
</template>

<script>
export default {
	props: {
		files: {
			type: Array,
			required: true,
		},
		filevalues: {
			type: Object,
			required: true,
		},
		fileproerr: {
			type: Object,
			required: true,
		},
		fileprostage: {
			type: Object,
			required: true,
		},
		fileuppercent: {
			type: Object,
			required: true,
		},
		storepath: {
			type: String,
			required: true,
		},
		fileuploadeCount: {
			type: String,
			required: true,
		},
		botId: {
			type: String,
			required: true,
		},
		// filecount: {
		//   type: Number,
		//   required: true,
		// },
		filesdataPros: {
			type: Object,
			required: true,
		},
		fileconfig: {
			type: Object,
			required: true,
		},
		editable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			filecount: 0,
			fileuploadpercent: {},
			file: {},
			typeOffile: false,
			typeOffileloading: false,
			fileuploadtypeItem: ['Replace existing', 'Add to existing', 'Replace or add to existing'],
			fileuploadtypeItemNofile: ['New File'],
			fileuptypefile: null,
			fileuptype: "",
			singleMultfileError: '',
			twofileId: "",
			twofileColum: "",
			fileUpDetil: "",
			requiredColum: null,
			getstoreFileid: null,
			fileuploadeList: [],
			headersFile: [{
					text: 'File Name',
					value: 'name'
				},
				{
					text: 'Action',
					value: 'action',
					sortable: false
				}
			],
			id: '',
			anaid: '',
			alaysisDetails: {},
			firstKey: '',
			fileuploadeornot:false,
		};
	},
	mounted() {
		this.getstoreFileid = Object.keys(this.fileconfig)[0]
		this.firstKey = Object.keys(this.fileconfig)[0];
		const config = this.fileconfig[this.firstKey];
		this.requiredColum = config;
		this.id = this.$route.params.id || "";
		this.anaid = this.$route.params.anaid;
		this.getTransctionmatching();

	},
	methods: {
		async getTransctionmatching() {
			this.loading = true;
			try {
				let response = await this.axios.post("/v2/financialclose/analysis/getTransactionMatchingAnalysis/" + this.id + '/' + this.anaid);
				if (response.data.status === "success" && response.data.data.length > 0) {
					this.alaysisDetails = response.data.data[0];
					// console.log(this.alaysisDetails,"alaysisDetails");
				}
			} catch (err) {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
			} finally {
				this.loading = false;
			}
		},
		fileuploadType() {
			this.fileuptypefile = null,
				this.fileuptype = "",
				this.singleMultfileError = '';
			this.fileuploadeList = [];
			this.typeOffile = true;

		},
		fileuploadTypeTwoFile(id, column ,fileexit) {
			console.log(fileexit);
			if (fileexit) {
				this.fileuploadeornot = false;			
			} else {
				this.fileuploadeornot = true;
			}
			this.fileuptypefile = null,
			this.fileuptype = "",
			this.twofileId = id;
			this.twofileColum = column;
			this.singleMultfileError = '';
			this.fileuploadeList = [];
			this.typeOffile = true;
		},

		async uploadeFileTwo(type, selectfiles,filecount) {
			// console.log(selectfiles, "fileType");
			// console.log(selectfiles, "fileType");
			console.log(filecount, "filecount");

			if (!type) {
				this.singleMultfileError = "Upload Type Required!";
				return;
			}

			if (!selectfiles || selectfiles.length === 0) {
				this.singleMultfileError = "File Required!";
				return;
			}
			this.typeOffileloading = true;
			const config = this.fileconfig[this.twofileId] || {};
			const columnArr = this.twofileColum.map(column => [column.name, column.name]);


			// if (filecount === 'More' || filecount === 'One File' || filecount === 'Two File') {
			// 	let fileIds = [];
			// 	let allFilesUploaded = true;
			// 	for (let i = 0; i < selectfiles.length; i++) {
			// 		if (selectfiles[i]) {
			// 			let files = selectfiles[i] || {};
			// 			try {
			// 				this.typeOffileloading = true;
			// 				await this.$nova.processConserveFile(files, config, columnArr);
			// 				const uploadResponse = await this.$nova.uploadFile(this.axios, files, {
			// 					progressfunction: (e) => {
			// 						let p = (e.loaded * 100) / e.total;
			// 						this.fileuploadpercent = p.toFixed(0);
			// 					},
			// 				});
			// 				if (uploadResponse.data.status === "success") {
			// 					this.fileUpDetil = uploadResponse.data.data[0];
			// 					fileIds.push(this.fileUpDetil._id);
			// 				} else {
			// 					throw new Error("Unable to upload file");
			// 				}
			// 			} catch (error) {
			// 				this.singleMultfileError = error.message || error || "Unknown error!";
			// 				console.log(error);
			// 				allFilesUploaded = false; // Mark as false if any upload fails
			// 				break;
			// 			} finally {
			// 				this.file = {};
			// 				this.typeOffileloading = false;
			// 			}
			// 		}
			// 	}
			// 	if (allFilesUploaded && fileIds.length > 0) {
			// 		this.typeOffileloading = true;
			// 		try {
			// 			const storeResponse = await this.axios.post(this.storepath, {
			// 				data: {
			// 					upload: fileIds,
			// 					file: this.twofileId,
			// 					uploadtype: type,
			// 					tmaid: this.alaysisDetails._id,
			// 				},
			// 			});

			// 			if (storeResponse.data.status === "success") {
			// 				this.$emit("storedData", {
			// 					"stored": this.fileUploadedDetilas
			// 				});
			// 				console.log("All file IDs have been successfully sent:", storeResponse.data.data);
			// 				this.$store.commit("sbSuccess", "All files uploaded successfully");
			// 			} else {
			// 				throw new Error(storeResponse.data.message || "Error sending file IDs");
			// 			}
			// 		} catch (error) {
			// 			this.$store.commit("sbError", error.message);
			// 			console.error("Error while sending the list of file IDs:", error.message || error || "Unknown error!");
			// 		} finally {
			// 			this.file = {};
			// 			this.typeOffileloading = false;
			// 			this.typeOffile = false;
			// 		}
			// 	}

			// }

			let file = selectfiles[0] || {};
			try {
				const processedFile = await this.$nova.processConserveFile(file, config, columnArr);
				console.log(processedFile);

				const uploadResponse = await this.$nova.uploadFile(this.axios, file, {
					progressfunction: (e) => {
						const progress = (e.loaded * 100) / e.total;
						this.fileuploadpercent[this.twofileId] = progress.toFixed(0);
						this.filecount++;
					}
				});

				if (uploadResponse.data.status === "success") {
					//console.log("Upload Response Data", uploadResponse.data.data);
					this.fileUpDetil = uploadResponse.data.data[0];

					const storeResponse = await this.axios.post(this.storepath, {
						data: {
							upload: this.fileUpDetil._id,
							file: this.twofileId,
							uploadtype: type,
							tmaid: this.alaysisDetails._id
						}
					});

					if (storeResponse.data.status === "success") {
						this.typeOffile = false;
						this.$store.commit("sbSuccess", "File Upload successfully");
						this.fileUploadedDetails = storeResponse.data.data;
						this.$emit("storedData", {
							stored: this.fileUploadedDetails,
							uploaded: file
						});
						setTimeout(() => {
							this.$emit("storedData", {
							stored: this.fileUploadedDetails,
							uploaded: file
						});
						}, 10000);
					} else {
						this.$store.commit("sbError", storeResponse.data.message || "Error storing file");
						throw new Error(storeResponse.data.message || "Error storing file");

					}
				} else {
					this.$store.commit("sbError", "Unable to upload file");
					throw new Error("Unable to upload file");
				}
			} catch (error) {
				console.log(error);
				this.singleMultfileError = error.message || error || "Unknown error!";
			} finally {
				this.typeOffileloading = false;
			}
		},

		async uploadeFiles(type, selectfiles, filecount) {
			if (!type) {
				this.singleMultfileError = "Upload Type Required!";
				return;
			}

			if (!selectfiles || selectfiles.length === 0) {
				this.singleMultfileError = "File Required!";
				return;
			}

			const firstKey = Object.keys(this.fileconfig)[0];
			const config = this.fileconfig[firstKey];
			const getColumFile = this.files[0].columns;
			const columArr = getColumFile.map(column => [column.name, column.name]);
			this.typeOffileloading = true;
			if (filecount === 'More' || filecount === 'One File') {
				let fileIds = [];
				let allFilesUploaded = true;
				for (let i = 0; i < selectfiles.length; i++) {
					if (selectfiles[i]) {
						let files = selectfiles[i] || {};
						try {
							this.typeOffileloading = true;
							await this.$nova.processConserveFile(files, config, columArr);
							const uploadResponse = await this.$nova.uploadFile(this.axios, files, {
								progressfunction: (e) => {
									let p = (e.loaded * 100) / e.total;
									this.fileuploadpercent = p.toFixed(0);
								},
							});
							if (uploadResponse.data.status === "success") {
								this.fileUpDetil = uploadResponse.data.data[0];
								fileIds.push(this.fileUpDetil._id);
							} else {
								throw new Error("Unable to upload file");
							}
						} catch (error) {
							this.singleMultfileError = error.message || error || "Unknown error!";
							console.log(error);
							allFilesUploaded = false; // Mark as false if any upload fails
							break;
						} finally {
							this.file = {};
							this.typeOffileloading = false;
						}
					}
				}
				if (allFilesUploaded && fileIds.length > 0) {
					this.typeOffileloading = true;
					try {
						const storeResponse = await this.axios.post(this.storepath, {
							data: {
								upload: fileIds,
								file: this.getstoreFileid,
								uploadtype: type,
								tmaid: this.alaysisDetails._id,
								isittm:true
							},
						});

						if (storeResponse.data.status === "success") {
							this.$emit("storedData", {
								"stored": this.fileUploadedDetilas
							});
							setTimeout(() => {
							this.$emit("storedData", {});
						}, 10000);
							console.log("All file IDs have been successfully sent:", storeResponse.data.data);
							this.$store.commit("sbSuccess", "All files uploaded successfully");
							
						} else {
							throw new Error(storeResponse.data.message || "Error sending file IDs");
						}
					} catch (error) {
						this.$store.commit("sbError", error.message);
						console.error("Error while sending the list of file IDs:", error.message || error || "Unknown error!");
					} finally {
						this.file = {};
						this.typeOffileloading = false;
						this.typeOffile = false;
					}
				}

			} else {
				let success = false;
				console.log(success);
				
				// let fileone = selectfiles[0] || {};
				// try {
				// 	this.typeOffileloading = true;
				// 	await this.$nova.processConserveFile(fileone, config, columArr);

				// 	const uploadResponse = await this.$nova.uploadFile(this.axios, fileone, {
				// 		progressfunction: (e) => {
				// 			let p = (e.loaded * 100) / e.total;
				// 			this.fileuploadpercent = p.toFixed(0);
				// 		},
				// 	});

				// 	if (uploadResponse.data.status === "success") {
				// 		this.fileUpDetil = uploadResponse.data.data[0];
				// 		const storeResponse = await this.axios.post(this.storepath, {
				// 			data: {
				// 				upload: this.fileUpDetil._id,
				// 				file: this.getstoreFileid,
				// 				uploadtype: type,
				// 				tmaid: this.alaysisDetails._id
				// 			},
				// 		});

				// 		if (storeResponse.data.status === "success") {
				// 			success = true;
				// 			this.typeOffile = false;
				// 			this.typeOffileloading = false;
				// 			this.$store.commit("sbSuccess", "File Upload successfully");
				// 			this.fileUploadedDetilas = storeResponse.data.data;
				// 			this.$emit("storedData", {
				// 				"stored": this.fileUploadedDetilas
				// 			});
				// 		} else {
				// 			throw new Error(storeResponse.data.message || "Error storing file");
				// 		}
				// 	} else {
				// 		throw new Error("Unable to upload file");
				// 	}
				// } catch (error) {
				// 	this.singleMultfileError = error.message || error || "Unknown error!";
				// 	console.log(error);
				// } finally {
				// 	this.file = {};
				// 	this.loading = false;
				// 	if (success) {
				// 		this.typeOffileloading = true;
				// 	} else {
				// 		this.typeOffileloading = false;
				// 	}
				// }
			}
		},
		deleteFile(index) {
			this.fileuploadeList.splice(index, 1);
		},
		uploadFileDelete(id){
		this.$emit('startloading')
			this.axios
			.post("/v2/financialclose/analysis/deleteFile/" + this.botId +'/'+ id)
			.then((ele) => {
				if (ele.data.status === "success") {
				this.$emit("storedData", { "stored": this.fileUploadedDetilas });
				} else {
					throw new Error(ele.data.message || "Error Deleteing File")
				}
			})
			.catch((err) => {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
				console.log(err);
			})
			.finally(() => {
				this.$emit('stoploading')
			});
			
		},
		allfileprocessed(arry){
			if (Array.isArray(arry)){
				return arry.every(item => item.processed === 1)
			} else 
			return false
			
		},
		callOtherFunction(id) {
			this.$emit("storedData", {});
			// setTimeout(() => {
			// 				this.$emit("storedData", {});
			// 			}, 10000);
       console.log(`Function automatically called for item with ID: ${id} because allfileprocessed returned false`);
      // Additional logic here
    },
		
	},
computed: {
    isAllFileProcessed() {
      return (id) => this.allfileprocessed(this.filevalues[id]);
    },
	allItemsProcessed() {
      if (Array.isArray(this.filevalues[this.firstKey])) {
        return this.filevalues[this.firstKey].every(item => item.processed === 1);
      }
      return false;
    }

  },
	watch: {
  async fileuptypefile(newFiles) {
    if (!newFiles) return;

    const showError = (message) => {
      this.$store.commit("sbError", message);
    };

    const processFile = async (files, config, columns) => {
      try {
        await this.$nova.processConserveFile(files, config, columns);
        this.fileuploadeList.push(files);
        this.fileuptypefile = null;
        this.singleMultfileError = '';
      } catch (error) {
        this.singleMultfileError = error.message || error || "Unknown error!";
        console.error('Error pushing file:', this.singleMultfileError);
      }
    };

    // if (this.fileuploadeCount === 'One File' && this.fileuploadeList.length > 0) {
    //   showError("Single file upload only");
    //   return;
    // }

    if (this.fileuploadeCount === 'Two File' && this.fileuploadeList.length > 0) {
      showError("Single file upload only");
      return;
    }

    let config, columnArr;

    if (this.fileuploadeCount === 'Two File') {
      config = this.fileconfig[this.twofileId] || {};
      columnArr = this.twofileColum.map(column => [column.name, column.name]);
    } else {
      const firstKey = Object.keys(this.fileconfig)[0];
      config = this.fileconfig[firstKey];
      const getColumFile = this.files[0].columns;
      columnArr = getColumFile.map(column => [column.name, column.name]);
    }

    console.log(newFiles, config, columnArr, "newFiles, config, columnArr");
    await processFile(newFiles, config, columnArr);
  },
    filevalues: {
      deep: true,
      handler(newFileValues) {
        Object.keys(newFileValues).forEach((id) => {
          if (!this.allfileprocessed(newFileValues[id])) {
            this.callOtherFunction(id);
          }
        });
      },
    },
}

};
</script>
<style scoped>
.mdi-cloud-upload-outline::before{
	font-size: 20px;
}
</style>
